<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Template bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form name="templateform" @submit.prevent="save" v-model="formValid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-switch v-model="template.active" label="Template aktiv" color="success"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                    <v-text-field
                      label="Name"
                      name="name"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="template.name"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                     <v-select
                      :items="templateTypes"
                      label="Type"
                      :rules="requiredRule"
                      color="purple"
                      v-model="template.type"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                     <v-textarea
                        solo
                        counter
                        clearable
                        auto-grow
                        name="template"
                        label="Template Code"
                        v-model="template.template"
                      ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ path: '/pdftemplates' }"
              color="error"
              type="button"
              name="cancel"
              >Abbrechen</v-btn
            >
            <v-spacer />
            <v-btn
              @click="save"
              color="success"
              name="submit"
              type="submit"
              :disabled="!formValid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TemplateForm",
  components: {},
  data: () => ({
 
    templateTypes: [
      {text: "Angebot", value:"offer"},
      {text: "Auftragsbestätigung", value:"confirmation"},
      {text: "Lieferschein", value:"delivery"},
      {text: "Rechnung", value:"bill"},
    ],
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ],
    formValid: false
  }),
  computed: {
    template(){
      return this.$store.getters["pdfTemplate/getCurrentTemplate"];
    }
  },
  methods: {
    checkAccess(role) {
     
      if (!this.user) {
        return false;
      }
      if (
        this.user.apps.roles.indexOf(
          this.$store.getters["user/userRoles"].userRole.SuperUser
        ) >= 0
      ) {
        return true;
      } else if (this.user.apps.roles.indexOf(role) >= 0) {
        return true;
      } else {
        return false;
      }
    },
    save() {
       this.$store
        .dispatch("pdfTemplate/saveTemplate", this.template)
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "pdftemplates" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Template gespeichert",
            text: "Template erfolgreich gespeichert"
          });
        })
        .catch((error) => {
          //on error alert error
          console.log(error);
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    }
  },
  created() {
     const id = this.$route.params.id;
    if (!id) {
      this.$store.dispatch("pdfTemplate/newTemplate").then(() => {
      });
    } else {
      this.$store
        .dispatch("pdfTemplate/fetchTemplate", id)
        .then(() => {
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        });
    }
  }
};
</script>
